@use 'sass:list';
@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.Process {
    background: $color_brand--secondary;

    .body {
        color: $color_font-dark;
        margin-bottom: $dims_padding-medium;

        ul, ol {
            padding-left: 1.2em;
            margin: $dims_padding-small * 2 0;

            li {
                line-height: 1.5em;
            }
        }
    }
}

.Accordions {
    background: $color_bg-light;

    #duluth {
        top: -22vh;
    }

    img {
        width: 100%;
    }

    .content {
        > h2, >h3 {
            display: none;
        }
    }

    .buttons {
        h3 {
            margin: 0;

            color: $color_brand;
            font-size: $font_size-small;
            font-weight: 400;
        }
        .Button {

            
            display: inline-block;

            width: max-content;
            margin-bottom: layout.$padding;

            background: $color_brand;
            padding: layout.$padding layout.$padding--large;
            border-radius: layout.$border-radius--large * 2;

            @include respond-to(tablet) {
                + .Button {
                    margin-left: layout.$padding;
                }
            }
        }
    }

    .belowgraphic {
        margin: $dims_padding-small 0 $dims_padding-medium;

        p {
            font-size: $font_size-medium !important;
        }
    }

    .Accordion {
        h3 {
            color: $color_brand;
        }

        .header {
            height: auto;

            span {
                font-size: $font_size-small;
                line-height: 1.2em;
            }
        }

        main {
            h3 {
                margin: 0;
                font-size: $font_size-small !important;
            }

            p {
                margin: math.div($dims_padding-small, 2) 0 $dims_padding-small * 2;
                line-height: 1.4em !important;
            }
        }
    }
}
