@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';


.Vision {

    background: $color_brand--secondary;

    p {
        font-size: $font_size-medium;
        color: $color_font-dark;
    }

    a {
        text-decoration: underline;
        color: $color_brand;
    }

    .row {
        margin: $dims_padding-medium 0;
    }

    .col {
        + .col {
            margin-top: $dims_padding-medium;
        }
    }

    img {
        width: 100%;
        border-radius: $box_radius-medium;
    }

    h4 {
        color: color.$white;
        font-weight: 400;
    }

    .buttons {
        display: flex;
        gap: layout.$padding;

        .Button {
            margin: 0;
            border: 3px solid transparent;
            font-weight: 800;
            font-family: $font_family-primary;
        }
    }

}

.system-modal {
    main {
        max-width: 1080px;
        width: max-content;
    }

    img {
        width: 100%;
        margin: auto;
    }
}
